body {
    /* color: #545454; */
    /* background-image: url('../images/horizon-moon-mountains-digital-art.jpg'); */
    background-repeat: no-repeat;
    box-shadow: #473232;
    font-size: 14px;
    font-family: 'NoirPro',sans-serif;
    font-variant: tabular-nums;
    line-height: 1.3;
    background-color: #473232;
    font-feature-settings: 'tnum';
}

.gx-app-login-wrap {
    height: 99.8vh;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    overflow-x: hidden;
}


.gx-app-login-container {
    position: relative;
    max-width:800px;
    width: 94%;
    margin: 0 auto;
    
}

.gx-app-login-main-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%);
    -moz-box-shadow: 0 0 5px 5px rgba(0,0,0,0.03);
    box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%);
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    font-size: 14px;
    overflow: hidden;
    height: 350px;
}

.gx-app-logo-content {
    color: #ffffff;
    /* padding: 35px 35px 20px; */
    width: 40%;
    position: relative;
    overflow: hidden;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.gx-app-logo-content-bg:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    right: 0;
    bottom: 0;
    /* background-color: rgba(3,143,222,0.7); */
}

*, *::before, *::after {
    box-sizing: border-box;
}

.gx-app-logo-content-bg img {
    width: 100%;
    height: 100%;
}

.gx-app-logo-content-bg {
    /* position: absolute; */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

img {
    vertical-align: middle;
    border-style: none;
}

