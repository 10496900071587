#newreo{
    padding: 0 !important;
    width: 35%;
    margin: 0 auto;
    margin-top: 15%;
    height: 150px;    
    position: absolute;
    
}

#newmultiple{
    padding: 0 !important;
    width: 80%;
    margin: 0 auto;
    margin-top: -20px;
    height: 77vh;    
    position: absolute;
}

#newHtml{
    padding: 0 !important;
    width: 38%;
    margin: 0 auto;
    margin-top: 50px;
    height: 49vh;    
    position: absolute;
}

#newreuse{
    padding: 0 !important;
    width: 38%;
    margin: 0 auto;
    margin-top: 50px;
    height: 49vh;    
    position: absolute;
}

#seginfo{
    padding: 0 !important;
    width: 70%;
    margin: 0 auto;
    margin-top: -20px;
    height: 88vh;    
    position: absolute;
}


#newsegreq{
    padding: 0 !important;
    width: 60%;
    margin: 0 auto;
    margin-top: 0px;
    height: 67vh;    
    position: absolute;
}

#newbat{
    padding: 0 !important;
    width: 60%;
    margin: 0 auto;
    margin-top: 4%;
    height: 50vh;    
    position: absolute;
}


.pagination-container {
    display: flex;
    justify-content: center; /* Centrer horizontalement */
    align-items: center; /* Centrer verticalement */
    margin-top: -10px; /* Espace entre le tableau et la pagination, à ajuster selon vos besoins */
  }
  
  .pagination {
    display: inline-block;
    margin-left: 10px; /* Espacement entre les éléments de la pagination */
  }
  
  /* Styles pour les liens "Précédent" et "Suivant" */
  .pagination a {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 5px; /* Espacement entre les liens de pagination */
    text-decoration: none;
    color: #333;
    background-color: #fff;
    cursor: pointer;
  }
  
  /* Style pour l'élément de pagination actif */
  .pagination .active a {
    background-color: #007bff;
    color: #fff;
  }
  