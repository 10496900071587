#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.ant-layout-sider .ant-layout-sider-dark {
  max-width: 200px !important;
  min-width: 200px !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-menu-dark {
  background: #003366 !important;
}

.ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark, .ant-menu.ant-menu-dark .ant-menu-sub {
    color: hsla(0, 0%, 100%, .65);
    /* background: #001529; */
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #003366;
}